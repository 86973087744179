import { ref, watch, computed } from '@vue/composition-api'
// Notification
import { debounce } from 'debounce'
import { startOfMonth, format } from 'date-fns'

export default function useAllTransaction() {
  // Use toast

  // Subtract 30 days from today's date
  const refTransactionTable = ref()
  const startDateFilter = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const endDateFilter = ref(format(new Date(), 'yyyy-MM-dd'))
  const selectedTransactionType = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'date' },
    { key: 'logId', label: 'Log ID' },
    { key: 'type', label: 'Log Type' },
    { key: 'amount' },
    { key: 'balance' },
    { key: 'description', label: 'Description' },
    { key: 'raceId' },
    { key: 'newRunner', label: 'New Runner no.' },
    { key: 'oldRunner', label: 'Old Runner no.' },
    { key: 'raceName' },
    { key: 'raceNumber' },
    { key: 'result' },
    { key: 'username' },
    { key: 'competitionId', label: 'Showdown ID' },
    { key: 'competitionName', label: 'SHOWDOWN - BSB/ACC' },
    { key: 'bonusCashUsed', label: 'Bonus Cash Used' },
    { key: 'betId' },
  ]

  const transactionTypes = [
    { label: 'All', value: null },
    { label: 'Race Result', value: 'race-results' },
    { label: 'Scratch Runner', value: 'scratch-runner' },
    { label: 'Flexi Roll Over Wallet', value: 'flexi-roll-over-wallet' },
    { label: 'Use Flexi Roll Over Wallet', value: 'use-flexi-roll-over-wallet' },
    { label: 'Refund Flexi Roll Over Wallet', value: 'refund-flexi-roll-over-wallet' },
  ]

  const resolveSourceVariant = val => {
    if (val === 'V') return 'light-success'
    if (val === 'N') return 'light-warning'
    if (val === 'Q') return 'light-primary'
    return 'primary'
  }

  const statusOptions = ['All', 'Pending', 'Reject', 'Approve', 'Complete']

  const perPage = ref(100)
  const totalTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const isSortDirDesc = ref(false)
  const sortBy = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTransactionTable.value ? refTransactionTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransactions.value,
    }
  })

  const refetchData = () => {
    refTransactionTable.value.refresh()
  }

  watch([currentPage, perPage, selectedTransactionType], () => {
    refetchData()
  })

  watch([endDateFilter, startDateFilter], () => {
    if (endDateFilter && startDateFilter) {
      refetchData()
    }
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalTransactions.value = 0
      refetchData()
    }, 700),
  )

  const resolveTransactionStatusVariant = status => {
    switch (status) {
      case 'pending':
        return 'primary'
      case 'reject':
        return 'danger'
      case 'approve':
        return 'info'
      case 'complete':
        return 'success'
      default:
        return ''
    }
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    refTransactionTable,
    isSortDirDesc,
    sortBy,

    startDateFilter,
    endDateFilter,

    statusOptions,
    resolveSourceVariant,

    selectedTransactionType,
    transactionTypes,

    resolveTransactionStatusVariant,
  }
}
