<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div
        id="filter"
        class="m-2"
      >
        <!-- Table Top -->

        <b-row class="mb-2">
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Filter by Transaction Type</label>
            <v-select
              v-model="selectedTransactionType"
              :options="transactionTypes"
              :reduce="transaction => transaction.value"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 d-flex align-items-center justify-content-center"
          >
            <span class="w-10">From:</span>

            <flat-pickr
              id="startDateFilter"
              v-model="startDateFilter"
              class="form-control ml-2"
              :config="config"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 d-flex align-items-center justify-content-center mt-1 mt-md-0"
          >
            <span class="w-10">To:</span>

            <flat-pickr
              id="endDateFilter"
              v-model="endDateFilter"
              class="form-control ml-2"
              :config="config"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end mt-1">
              <label>Show</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>transactions</label>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <b-table
          ref="refTransactionTable"
          :items="getListTransaction"
          :fields="tableColumns"
          responsive
          show-empty
          primary-key="transaction-table"
          style="height: calc(100vh - 400px); min-height: 400px"
          sticky-header="600px"
        >
          <template #empty>
            <div
              v-if="loadingLogList"
              class="text-center my-2"
            >
              <p>Loading... Please wait</p>
            </div>

            <div
              v-else-if="!loadingLogList && totalLogs === 0"
              class="text-center my-2"
            >
              No matching records found
            </div>
          </template>
          <!-- Column: date -->
          <template #cell(date)="{ item }">
            <span class="align-text-top text-capitalize">{{ formatDateTimeLocal(item.date) }}</span>
          </template>
          <!-- Column: LOG ID -->
          <template #cell(logId)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.logId }}</span>
          </template>

          <!-- Column: type -->
          <template #cell(type)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.type }}</span>
          </template>
          <!-- Column: amount -->
          <template #cell(amount)="{ item }">
            <span class="align-text-top text-capitalize">{{ flexiAmount(item) }}</span>
          </template>
          <!-- Column: balance -->
          <template #cell(balance)="{ item }">
            <span class="align-text-top text-capitalize">{{ flexiBalance(item) }}</span>
          </template>
          <!-- Column: description -->
          <template #cell(transactionableType)="{ item }">
            <span class="align-text-top text-capitalize">{{ description(item) }}</span>
          </template>
          <!-- Column: raceId -->
          <template #cell(raceId)="{ item }">
            <span class="align-text-top">{{
              item.metaData.raceId && item.type === 'scratch-runner'
                ? item.metaData.raceId
                : item.metaData.id && item.type === 'race-results'
                  ? item.metaData.id
                  : 'N/A'
            }}</span>
          </template>
          <!-- Column: newRunner -->
          <template #cell(newRunner)="{ item }">
            <span class="align-text-top">{{
              item.metaData.newRunnerNumber ? item.metaData.newRunnerNumber : 'N/A'
            }}</span>
          </template>
          <!-- Column: oldRunner -->
          <template #cell(oldRunner)="{ item }">
            <span class="align-text-top">{{
              item.metaData.oldRunnerNumber ? item.metaData.oldRunnerNumber : 'N/A'
            }}</span>
          </template>
          <!-- Column: raceName -->
          <template #cell(raceName)="{ item }">
            <span class="align-text-top">{{ item.metaData.name ? item.metaData.name : 'N/A' }}</span>
          </template>
          <!-- Column: raceNumber -->
          <template #cell(raceNumber)="{ item }">
            <span class="align-text-top">{{ item.metaData.number ? item.metaData.number : 'N/A' }}</span>
          </template>
          <!-- Column: result -->
          <template #cell(result)="{ item }">
            <feather-icon
              v-if="item.type === 'race-results'"
              icon="EyeIcon"
              size="16"
              class="mx-1"
              style="cursor: pointer"
              @click="handleShowDetailResult(item)"
            />
          </template>
          <!-- Column: username -->
          <template #cell(username)="{ item }">
            <span class="align-text-top">{{ item.username ? item.username : 'N/A' }}</span>
          </template>
          <!-- Column: competitionId -->
          <template #cell(competitionId)="{ item }">
            <span class="align-text-top text-capitalize">{{
              item.type === 'deposit' || item.type === 'withdrawal' ? 'N/A' : item.logableId ? item.logableId : 'N/A'
            }}</span>
          </template>
          <!-- Column: leg -->
          <template #cell(leg)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.leg ? item.leg : 'N/A' }}</span>
          </template>
          <!-- Column: runner -->
          <template #cell(runner)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.runner ? item.runner : 'N/A' }}</span>
          </template>
          <!-- Column: placing -->
          <template #cell(placing)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.placing ? item.placing : 'N/A' }}</span>
          </template>
          <!-- Column: odds -->
          <template #cell(odds)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.odds ? item.odds : 'N/A' }}</span>
          </template>
          <!-- Column: payout -->
          <template #cell(payout)="{ item }">
            <span class="align-text-top text-capitalize">{{
              item.metaData.amount && (item.type === 'transfer-main-prize' || item.type === 'transfer-entry-prize')
                ? formatCurrencyBuiltInFunc(item.metaData.amount)
                : 'N/A'
            }}</span>
          </template>
          <!-- Column: cash amount -->
          <template #cell(purchaseAmount)="{ item }">
            <span class="align-text-top text-capitalize">{{ purchaseAmount(item) }}</span>
          </template>

          <!-- Column: bonus cash used -->
          <template #cell(bonusCashUsed)="{ item }">
            <span class="align-text-top text-capitalize">{{
              item.metaData && item.metaData.usedBonus && item.metaData.usedBonus.length
                ? formatCurrencyBuiltInFunc(item.metaData.usedBonus.reduce((acc, item) => acc + item.used, 0))
                : 'N/A'
            }}</span>
          </template>
          <!-- Column: betId -->
          <template #cell(betId)="{ item }">
            <span class="align-text-top text-capitalize">{{
              item.metaData.betSlipId ? item.metaData.betSlipId : 'N/A'
            }}</span>
          </template>
          <!-- Column: betStatus -->
          <template #cell(betStatus)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.betStatus ? item.betStatus : 'N/A' }}</span>
          </template>
          <!-- Column: Showdown Status -->
          <template #cell(status)="{ item }">
            <span class="align-text-top text-capitalize">{{ item.status ? item.status : 'N/A' }}</span>
          </template>

          <!-- Column: competitionName -->
          <template #cell(competitionName)="{ item }">
            <span class="align-text-top text-capitalize">{{ showdownName(item) }}</span>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} transactions
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalLogs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="modal"
      title="Result detail"
      centered
      cancel-variant="outline-secondary"
      hide-footer
      @ok="handleSubmitModal"
    >
      <div
        v-if="itemShowInModal"
        style="color: black"
      >
        <div
          v-for="(item, ind) in itemShowInModal.metaData.results"
          :key="ind"
          style="margin-bottom: 6px"
        >
          <div style="display: flex; align-items: center; gap: 4px">
            <span style="font-weight: bold">Source:</span>
            <b-badge
              pill
              :variant="resolveSourceVariant(item.source)"
              style="font-weight: bold"
            >
              {{ item.source }}
            </b-badge>
          </div>
          <ul class="list-style-icons">
            <li
              v-for="(i, index) in item.positions"
              :id="ind + 'popover' + index"
              :key="index"
              style="display: flex; align-items: center; gap: 6px; cursor: pointer;"
            >
              <feather-icon icon="ChevronRightIcon" />
              <p style=" margin: 0px !important;">
                Position: {{ i.position }}, tabNo: {{ i.tabNo }}
              </p>
              <b-popover
                :target="ind + 'popover' + index"
                triggers="hover"
                placement="top"
                style="color: black;"
                custom-class="my-popover-class"
              >
                <template #title>
                  <p style="text-align: center">
                    Dividends detail <br>(Runner Number: {{ i.tabNo }})<br> Position: {{ i.position }}
                  </p>
                </template>
                <ul
                  class="list-style-icons"
                  style="color: black;"
                >
                  <p
                    v-if="i.resultSet.dividends.length === 0"
                    style="text-align: center;"
                  >
                    No dividend
                  </p>
                  <div
                    v-else
                    style="max-height: 500px; overflow-y: scroll;"
                  >
                    <li
                      v-for="(x, y) in i.resultSet.dividends"
                      :key="y"
                      style="display: flex; align-items: center; gap: 6px; cursor: pointer;"
                    >

                      <p style="color: black;">
                        <span style="font-weight: bold;"> Type:</span> {{ x.type }}, <span style="font-weight: bold;"> dividend:</span>  {{ x.dividend }}, <span style="font-weight: bold;"> dividendNumbers:</span>  {{ x.dividendNumbers }}
                      </p>

                    </li>

                  </div>
                </ul>
              </b-popover>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  // BDropdown,
  // BDropdownItem,
  // BButton,
  // BCardText,
  VBModal,
  BBadge,
  BPopover,
} from 'bootstrap-vue'
import { TransactionAction } from '@core/utils/enum/transaction'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import useToastHandler from '@/services/toastHandler'
import {
  formatDate,
  formatDateTimeLocal,
  formatCurrencyBuiltInFunc,
  convertTimeEnd,
  convertTimeStart,
} from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import useAllTransactions from './useAllTransaction.js'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    // BDropdown,
    // BDropdownItem,
    flatPickr,
    // BButton,
    // BCardText,
    // Spinner,
    BBadge,
    BPopover,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      Spinner,
      loadingExportFile: null,
      filterStatus: 'All',
      actionList: ['Approve', 'Decline', 'Review'],
      config: {
        dateFormat: 'Y-m-d',
        maxDate: new Date(),
      },
      modal: {
        title: '',
        content: '',
      },
      action: '',
      transactionIdRefund: null,

      itemShowInModal: null,
    }
  },
  computed: {
    ...mapState('transaction/log', ['loadingLogList', 'totalLogs']),

    // isSubmitting() {
    //   return this.loadingRefundTransaction
    // },

    description() {
      return item => {
        if (item.type === 'deposit') {
          return (
            item.metaData.paymentId
            || item.metaData.googlePayTransactionId
            || item.metaData.applePayTransactionId
            || 'N/A'
          )
        }
        if (item.type === 'withdrawal') {
          return 'Bank Account'
        }
        return item.transactionableType || 'N/A'
      }
    },

    showdownName() {
      return item => {
        if (item.type === 'deposit') {
          return `Deposit Method - ${item.metaData.depositType}`
        }
        if (item.type === 'withdrawal') {
          return `${item.bsb} - ${item.number}`
        }
        return item.competitionName || 'N/A'
      }
    },

    purchaseAmount() {
      return item => {
        if (
          item.type === 'deposit'
          || item.type === 'withdrawal'
          || item.type === 'refund-join-comp'
          || item.type === 'refund-withdrawal'
          || item.type === 'update-balance'
        ) {
          return formatCurrencyBuiltInFunc(item.metaData.amount)
        }

        if (
          item.type === 'join-comp'
          || item.type === 'quick-join-comp'
          || item.type === 'reuse-bet-slip'
          || item.type === 'rebuy'
          || item.type === 'buy-voucher'
        ) {
          return formatCurrencyBuiltInFunc(item.metaData.oldBalance - item.metaData.balance)
        }
        return 'N/A'
      }
    },
    heightFilter() {
      return document.getElementById('filter').clientHeight
    },
    flexiAmount() {
      return item => {
        if (
          item.type === 'flexi-roll-over-wallet'
          || item.type === 'use-flexi-roll-over-wallet'
          || item.type === 'refund-flexi-roll-over-wallet'
        ) {
          return formatCurrencyBuiltInFunc(item.metaData.amount)
        }
        return 'N/A'
      }
    },
    flexiBalance() {
      return item => {
        if (
          item.type === 'flexi-roll-over-wallet'
          || item.type === 'use-flexi-roll-over-wallet'
          || item.type === 'refund-flexi-roll-over-wallet'
        ) {
          return formatCurrencyBuiltInFunc(item.metaData.balance)
        }
        return 'N/A'
      }
    },
  },

  methods: {
    ...mapActions({
      getLogList: 'transaction/log/getLogList',
      // handleRefundTransaction: 'transaction/detail/handleRefundTransaction',
    }),

    formattedBonusCashColumn(data) {
      if (data.type === 'cancelled-betslip') {
        if (!data.metaData || !data.metaData.refundBonus) return 'N/A'
        return formatCurrencyBuiltInFunc(data.metaData.refundBonus.reduce((acc, item) => acc + item.used, 0))
      }

      if (data.type === 'promo') {
        return formatCurrencyBuiltInFunc(data.metaData.amount)
      }
      return 'N/A'
    },
    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },

    async handleSubmitModal(event) {
      event.preventDefault()

      // if (this.action === TransactionAction.REFUND) await this.handleRefund()

      if (!this.isSubmitting) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async getListTransaction() {
      const data = await this.getLogList({
        limit: this.perPage,
        page: this.currentPage,
        'date[startDate]': convertTimeStart(this.startDateFilter),
        'date[endDate]': convertTimeEnd(this.endDateFilter),
        type: this.selectedTransactionType,
      })
      this.totalTransactions = this.totalLogs
      return data
    },
    formatValueAction(val) {
      if (!val) return ''
      const valNumber = val.slice(0, 1)
      return this.actionList[Number(valNumber)]
    },
    handleShowDetailResult(item) {
      this.itemShowInModal = item
      this.$refs.modal.show()
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      refTransactionTable,
      sortBy,
      startDateFilter,
      endDateFilter,

      statusOptions,
      selectedTransactionType,
      transactionTypes,
      refetchData,
      resolveTransactionStatusName,

      resolveSourceVariant,
      resolveTransactionStatusVariant,
    } = useAllTransactions()

    return {
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      refTransactionTable,
      isSortDirDesc,
      sortBy,
      selectedTransactionType,
      startDateFilter,
      endDateFilter,
      transactionTypes,
      statusOptions,

      resolveTransactionStatusName,
      resolveTransactionStatusVariant,
      resolveSourceVariant,

      showToastError,
      showToastSuccess,
      refetchData,
      formatDate,
      formatDateTimeLocal,
      formatCurrencyBuiltInFunc,
      TransactionAction,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.dark-layout
  .flatpickr-calendar
  .flatpickr-days
  .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #ccc;
}

.per-page-selector {
  width: 90px;
}

.hidden-text {
  opacity: 0;
  visibility: hidden;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.w-fit {
  width: fit-content;
}
.card-body {
  @media screen and (max-width: 640px) {
    padding-block: 0 !important;
  }
}
.w-10 {
  display: inline-block;
  width: 50px;
}

.text-title {
  color: #000;
}

.my-popover-class {
  min-width: 350px;
  margin-left: 50px;
}
</style>
<!--
<style lang="scss" scoped>
.dark-layout
  .flatpickr-calendar
  .flatpickr-days
  .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #ccc;
}

</style> -->
